<style scoped>
.ivu-table .demo-table-info-row td {
    background-color: #2db7f5;
    color: #fff;
    padding-left: 3px !important;
    padding-right: 3px !important;
}
.ivu-table td.demo-table-info-column {
    background-color: #2db7f5;
    color: #fff;
}
.cursor-pointer {
    cursor: pointer;
}
</style>
<template>
    <div>
        <div class="demo-spin-article">
            <div class="row mb-3">
                <div class="col-md-6">
                    <label class="form-col-label control-label required">{{
                        $t("coaMapping.loanType")
                    }}</label>
                    <select
                        ref="loan_type_id"
                        v-model.number="model.loan_type_id"
                        class="form-select"
                        :class="{ 'is-invalid': errors.has('loan_type_id') }"
                    >
                        <option
                            :value="null"
                            disabled
                            selected
                            style="display: none;"
                            >{{ $t("select") }}</option
                        >
                        <option
                            v-for="(type, index) in loanTypes"
                            :key="index"
                            :value="type.loan_type_id"
                            >{{ type.loan_type_en }} | Admin fee:
                            {{ type.admin_fee_rate }} | Insurance fee:
                            {{ type.insurance_fee_rate }}
                        </option>
                    </select>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('loan_type_id')"
                    >
                        {{ errors.first("loan_type_id") }}
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="form-col-label control-label required">{{
                        $t("coaMapping.transactionType")
                    }}</label>
                    <select
                        v-model.number="model.tran_id"
                        class="form-select"
                        :class="{ 'is-invalid': errors.has('tran_id') }"
                        @change="onChangeTransactionType"
                    >
                        <option
                            :value="null"
                            disabled
                            selected
                            style="display: none;"
                            >{{ $t("select") }}</option
                        >
                        <option
                            v-for="(type, index) in transactionTypes"
                            :key="index"
                            :value="type.tran_id"
                            >{{ type.tran_name }}
                        </option>
                    </select>
                    <div class="invalid-feedback" v-if="errors.has('tran_id')">
                        {{ errors.first("tran_id") }}
                    </div>
                </div>
            </div>
            <div class="row tw-mb-6">
                <div class="col-md-6">
                    <label class="form-col-label control-label required">{{
                        $t("coaMapping.currency")
                    }}</label>
                    <select
                        v-model.number="model.currency_id"
                        class="form-select"
                        :class="{ 'is-invalid': errors.has('currency_id') }"
                        @change="getCoaSetup"
                        @input="model.details = []"
                    >
                        <option
                            :value="null"
                            disabled
                            selected
                            style="display: none;"
                            >{{ $t("select") }}</option
                        >
                        <option
                            v-for="(c, index) in currencies"
                            :key="index"
                            :value="c.currency_id"
                            >{{ c.currency_name_en }} |
                            {{ c.currency_code }}</option
                        >
                    </select>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('currency_id')"
                    >
                        {{ errors.first("currency_id") }}
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="form-col-label control-label">{{
                        $t("coaMapping.remarks")
                    }}</label>
                    <textarea
                        v-model="model.remarks"
                        rows="1"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('remarks') }"
                    ></textarea>
                    <div class="invalid-feedback" v-if="errors.has('remarks')">
                        {{ errors.first("remarks") }}
                    </div>
                </div>
            </div>
            <div class="tw-mb-6">
                <fieldset>
                    <legend class="text-blue">
                        {{ $t("coaMapping.chartOfAccount") }}
                        <Tooltip placement="top">
                            <Icon
                                type="ios-information-circle-outline"
                                size="17"
                            />
                            <div slot="content">
                                <div class="tw-text-xs">
                                    Double click on table row or click icon
                                </div>
                                <span class="tw-text-xs"
                                    >plus to add item in to item details.</span
                                >
                            </div>
                        </Tooltip>
                    </legend>
                    <div class="col-md-12">
                        <div class="tw-flex tw-justify-between tw-mb-3 tw-mt-2">
                            <select
                                v-model.number="model.acc_cat_id"
                                class="form-select"
                                style="width: 309px"
                                @change="getCoaSetup"
                            >
                                <option
                                    :value="null"
                                    disabled
                                    selected
                                    style="display: none;"
                                    >{{
                                        $t("coaMapping.filterByCoaCategory")
                                    }}</option
                                >
                                <option
                                    v-for="(cat, index) in coaCategories"
                                    :key="index"
                                    :value="cat.acc_cat_id"
                                    >{{ cat.acc_cat_name_en }} |
                                    {{ cat.acc_cat_type }}
                                </option>
                            </select>
                            <Input
                                v-model="model.search"
                                search
                                :placeholder="$t('coaMapping.searchCoaSetup')"
                                style="width: 309px"
                            />
                        </div>
                        <Table
                            height="150"
                            border
                            :columns="columns"
                            :data="coaSetupLists"
                            size="small"
                            :loading="coaSetupLoading"
                            @on-row-dblclick="onAddItem"
                            :row-class-name="rowClassName"
                            stripe
                        >
                            <template slot-scope="{ row }" slot="currency_id">
                                {{
                                    row.currency
                                        ? row.currency.currency_name_en +
                                          "|" +
                                          row.currency.currency_code
                                        : ""
                                }}
                            </template>
                            <template slot-scope="{ row }" slot="action">
                                <Tooltip
                                    placement="top"
                                    :transfer="true"
                                    :content="$t('add')"
                                >
                                    <a
                                        href="#"
                                        class="text-blue"
                                        @click.prevent="onAddItem(row)"
                                    >
                                        <Icon
                                            type="ios-add-circle-outline"
                                            size="20"
                                        />
                                    </a>
                                </Tooltip>
                            </template>
                        </Table>
                    </div>
                </fieldset>
            </div>
            <div class="mt-3 mb-3">
                <fieldset>
                    <legend class="text-blue">
                        {{ $t("coaMapping.itemDetail") }}
                        <Tooltip placement="top">
                            <Icon
                                type="ios-information-circle-outline"
                                size="17"
                            />
                            <div slot="content">
                                <div class="tw-text-xs">
                                    CoA mapping item details.
                                </div>
                            </div>
                        </Tooltip>
                    </legend>
                    <div class="tw-mt-2 overflow-auto tw-max-h-40">
                        <div class="table-ui-detail">
                            <table>
                                <thead>
                                    <tr>
                                        <th width="10%">
                                            {{ $t("coaMapping.accountNumber") }}
                                        </th>
                                        <th width="25%">
                                            {{ $t("coaMapping.accountName") }}
                                        </th>
                                        <th class="text-center" width="5%">
                                            {{ $t("coaMapping.currencyCode") }}
                                        </th>
                                        <th class="text-center" width="5%">
                                            {{ $t("coaMapping.displayOrder") }}
                                        </th>
                                        <th class="text-center" width="20%">
                                            {{ $t("coaMapping.accountSide") }}
                                        </th>
                                        <th class="text-center" width="20%">
                                            {{ $t("coaMapping.sourceValue") }}
                                        </th>
                                        <th width="1%">
                                            <Tooltip placement="left">
                                                <Icon
                                                    type="ios-information-circle-outline"
                                                    size="17"
                                                />
                                                <div slot="content">
                                                    <span class="tw-text-xs">{{
                                                        $t(
                                                            "coaMapping.isUseCustomSourceValue"
                                                        )
                                                    }}</span>
                                                </div>
                                            </Tooltip>
                                        </th>
                                        <th class="text-center" width="5%">
                                            {{ $t("actions") }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(detail, index) in items"
                                        :key="index"
                                    >
                                        <td class="tw-whitespace-nowrap">
                                            {{ detail.account_number }}
                                        </td>
                                        <td class="tw-whitespace-nowrap">
                                            {{ detail.account_name_en }}
                                        </td>
                                        <td
                                            class="text-center tw-whitespace-nowrap"
                                        >
                                            {{ detail.currency_code }}
                                        </td>
                                        <td
                                            class="text-center tw-whitespace-nowrap"
                                        >
                                            <input type="number" v-model.number="detail.display_order" class="form-control form-control-sm text-center"/>
                                        </td>
                                        <td
                                            class="text-center tw-whitespace-nowrap"
                                        >
                                            <RadioGroup
                                                v-model="detail.mapping_side"
                                                type="button"
                                                button-style="solid"
                                                size="small"
                                            >
                                                <Radio
                                                    label="Debit"
                                                ></Radio>
                                                <Radio
                                                    label="Credit"
                                                ></Radio>
                                            </RadioGroup>
                                            <div
                                                class="validate text-danger"
                                                v-if="
                                                    errors.has(
                                                        'details.' +
                                                            index +
                                                            '.mapping_side'
                                                    )
                                                "
                                            >
                                                {{
                                                    errors.first(
                                                        "details." +
                                                            index +
                                                            ".mapping_side"
                                                    )
                                                }}
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <select
                                                v-model.number="
                                                    detail.source_value
                                                "
                                                class="form-select form-select-sm"
                                                :class="{
                                                    'is-invalid': errors.has(
                                                        'details.' +
                                                            index +
                                                            '.source_value'
                                                    )
                                                }"
                                            >
                                                <option
                                                    :value="null"
                                                    disabled
                                                    selected
                                                    style="display: none;"
                                                    >{{ $t("select") }}</option
                                                >
                                                <option
                                                    v-for="(source,
                                                    index) in sourceValues"
                                                    :key="index"
                                                    :value="source.source_value"
                                                    >{{ source.source_value }}
                                                </option>
                                            </select>
                                            <div
                                                class="invalid-feedback"
                                                v-if="
                                                    errors.has(
                                                        'details.' +
                                                            index +
                                                            '.source_value'
                                                    )
                                                "
                                            >
                                                {{
                                                    errors.first(
                                                        "details." +
                                                            index +
                                                            ".source_value"
                                                    )
                                                }}
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <ts-checkbox
                                                v-model="
                                                    detail.is_use_custom_source_value
                                                "
                                            />
                                        </td>
                                        <td class="text-center">
                                            <Tooltip
                                                placement="top"
                                                :transfer="true"
                                                :content="$t('remove')"
                                            >
                                                <a
                                                    href="#"
                                                    class="text-danger"
                                                    @click.prevent="
                                                        removeRow(detail)
                                                    "
                                                >
                                                    <Icon
                                                        type="ios-remove-circle-outline"
                                                        size="20"
                                                    />
                                                </a>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </fieldset>
                <div class="validate text-danger" v-if="errors.has('details')">
                    {{ errors.first("details") }}
                </div>
            </div>
            <div slot="footer">
                <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh">
                    <ts-button
                        @click.prevent="() => $emit('cancel')"
                        class="btn-gray"
                    >
                        {{ $t("cancel") }}</ts-button
                    >
                    <ts-button
                        color="primary"
                        v-if="!isUpdate"
                        outline
                        :waiting="waiting_new"
                        :disabled="waiting"
                        @click.prevent="onSaveAddNew"
                    >
                        {{ $t("saveAddNew") }}</ts-button
                    >
                    <ts-button
                        color="primary"
                        v-if="!isUpdate"
                        :waiting="waiting"
                        :disabled="waiting_new"
                        @click.prevent="onSave"
                    >
                        {{ $t("save") }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        @click.prevent="onUpdate"
                        :waiting="waiting"
                    >
                        {{ $t("update") }}</ts-button
                    >
                </div>
            </div>
        </div>
        <Spin size="large" fix v-if="loading"> </Spin>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapState } from "vuex";
import { isEmpty, debounce, orderBy } from "lodash";

export default {
    name: "coaMappingForm",
    data() {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            coaSetupLoading: false,
            coaSetups: [],
            sourceValues: [],
            model: {
                tran_id: null,
                loan_type_id: null,
                currency_id: null,
                remarks: null,
                acc_cat_id: null,
                search: null,
                details: []
            }
        };
    },
    computed: {
        ...mapState("accounting/coaMapping", [
            "edit_data",
            "loanTypes",
            "coaCategories",
            "transactionTypes",
            "currencies"
        ]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        },
        items(){
            return orderBy(this.model.details, ['display_order']);
        },
        columns() {
            return [
                {
                    title: this.$t("coaMapping.accountNumber"),
                    key: "account_number",
                    fixed: "left",
                    width: 100
                },
                {
                    title: this.$t("coaMapping.accountName"),
                    key: "account_name_en"
                },
                {
                    title: this.$t("coaMapping.currency"),
                    slot: "currency_id",
                    align: "center",
                    width: 120
                },
                {
                    title: this.$t("actions"),
                    slot: "action",
                    fixed: "right",
                    align: "center",
                    className: "tw-space-x-2",
                    width: 85
                }
            ];
        },
        coaSetupLists() {
            return orderBy(this.coaSetups, ['currency_id']);
        }
    },
    methods: {
        ...mapActions("accounting/coaMapping", [
            "getLoanType",
            "getCurrency",
            "getTransactionType",
            "getCoaCategory"
        ]),
        async fetchResource() {
            this.loading = true;
            await this.getLoanType();
            await this.getTransactionType();
            await this.getCoaCategory();
            await this.getCurrency();
            this.getCoaSetup();
            this.setEditData();
            this.loading = false;
        },
        rowClassName() {
            return "cursor-pointer";
        },
        cellClassName() {
            return "demo-table-info-column";
        },
        onAddItem(row) {
            this.model.details.push({
                mapping_id: 0,
                account_id: row.account_id,
                account_number: row.account_number,
                account_name_en: row.account_name_en,
                currency_code: row.currency ? row.currency.currency_code : "",
                mapping_side: null,
                source_value: null,
                is_use_custom_source_value: false,
                display_order: this.items.length + 1
            });
        },
        removeRow(item) {
            this.model.details.splice(this.model.details.indexOf(item), 1);
        },
        getCoaSetup() {
            this.coaSetupLoading = true;
            this.$store
                .dispatch("accounting/coaMapping/getCoaSetup", this.model)
                .then(response => {
                    this.coaSetups = response.data;
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.coaSetupLoading = false;
                });
        },
        onChangeTransactionType() {
            this.model.details = [];
            this.$store
                .dispatch("accounting/coaMapping/getSourceValue", {
                    tran_id: this.model.tran_id
                })
                .then(response => {
                    this.sourceValues = response.data;
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                });
        },
        onSaveAddNew() {
            this.errors = new Errors();
            this.waiting_new = true;
            this.$store
                .dispatch("accounting/coaMapping/store", this.model)
                .then(response => {
                    this.notice({ type: "success", text: response.message });
                    this.clearInput();
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting_new = false;
                });
        },
        onSave() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("accounting/coaMapping/store", this.model)
                .then(response => {
                    this.notice({ type: "success", text: response.message });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        onUpdate() {
            this.waiting = true;
            this.$store
                .dispatch("accounting/coaMapping/update", {
                    id: this.edit_data.mapping_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({ type: "success", text: response.message });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        async setEditData() {
            this.$nextTick(function() {
                this.$refs.loan_type_id.focus();
            });
            if (!isEmpty(this.edit_data)) {
                this.model.tran_id = this.edit_data.tran_id;
                this.model.currency_id = this.edit_data.currency_id;
                await this.getCoaSetup();
                await this.onChangeTransactionType();
                this.model.tran_id = this.edit_data.tran_id;
                this.model.loan_type_id = this.edit_data.loan_type_id;
                this.model.currency_id = this.edit_data.currency_id;
                this.model.remarks = this.edit_data.remarks;
                this.edit_data.details.forEach(detail => {
                    this.model.details.unshift({
                        mapping_id: detail.mapping_id,
                        account_id: detail.account_id,
                        account_number: detail.account.account_number,
                        account_name_en: detail.account.account_name_en,
                        display_order: detail.display_order,
                        currency_code: detail.account.currency
                            ? detail.account.currency.currency_code
                            : "",
                        mapping_side: detail.mapping_side,
                        source_value: detail.source_value,
                        is_use_custom_source_value:
                            detail.is_use_custom_source_value
                    });
                });
            }
        },
        clearInput() {
            this.model.tran_id = null;
            this.model.loan_type_id = null;
            this.model.currency_id = null;
            this.model.remarks = null;
            this.model.acc_cat_id = null;
            this.model.search = null;
            this.model.details = [];
            this.getCoaSetup();
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "CoA MAPPING",
                desc: not.text
            });
        }
    },
    watch: {
        "model.search": debounce(function() {
            this.getCoaSetup();
        }, 500)
    }
};
</script>
