<template>
    <div>
        <Table :columns="columns" :data="resources" size="small" stripe>
            <template slot-scope="{ row }" slot="account_number">
                {{ row.account ? row.account.account_number : "" }}
            </template>
            <template slot-scope="{ row }" slot="account_name">
                {{ row.account ? row.account.account_name_en : "" }}
                {{
                    row.account.account_name_kh
                        ? "|" + row.account.account_name_kh
                        : ""
                }}
            </template>
            <template slot-scope="{ row }" slot="mapping_side">
                <span
                    v-if="row.mapping_side == 'Debit'"
                    class="badge bg-success tw-mr-1 tw-capitalize"
                >
                    {{ row.mapping_side }}
                </span>
                <span v-else class="badge bg-danger tw-mr-1 tw-capitalize">
                    {{ row.mapping_side }}
                </span>
            </template>
            <template slot-scope="{ row }" slot="is_use_custom_source_value">
                <span
                    v-if="row.is_use_custom_source_value"
                    class="badge bg-success tw-mr-1 tw-capitalize"
                >
                    Yes
                </span>
                <span v-else class="badge bg-secondary tw-mr-1 tw-capitalize">
                    No
                </span>
            </template>
        </Table>
    </div>
</template>
<script>
import { orderBy } from "lodash"
export default {
    props: {
        row: Object
    },
    computed: {
        resources() {
            return orderBy(this.row.details, ['display_order']);
        },
        columns() {
            return [
                {
                    title: this.$t("coaMapping.accountNumber"),
                    slot: "account_number",
                    sortable: true,
                    width: 150
                },
                {
                    title: this.$t("coaMapping.accountName"),
                    slot: "account_name",
                    sortable: true
                },
                {
                    title: this.$t("coaMapping.mappingSide"),
                    slot: "mapping_side",
                    align: "center",
                    width: 150
                },
                {
                    title: this.$t("coaMapping.sourceValue"),
                    key: "source_value",
                    sortable: true,
                    width: 250
                },
                {
                    title: this.$t("coaMapping.isUseCustomSourceValue"),
                    slot: "is_use_custom_source_value",
                    align: "center",
                    width: 250
                }
            ];
        }
    }
};
</script>
